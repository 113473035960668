@import url('https://fonts.googleapis.com/css2?family=Sora:wght@500;600;700;800&display=swap');

* {
  font-family: Sora, Roboto, 'Open Sans', sans-serif;
}

body {
  background-color: #1F1F1F;
}

/* Header */
h1 {
  color: white;
  font-size: 2.75em;
  background: -webkit-linear-gradient(45deg, #6100FF, #FF008A, #FFC83A);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -85px;
}

#logo {
  position: relative;
  top: -110px;
  left: 130px;
  width: 95px;
  z-index: -1;
}

/* Main */
#mainContainer {
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
}

#form,
#preview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #313131;
  border-radius: 10px;
  padding: 15px;
  width: 43vw;
}

.eduDiv,
.expDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 43vw;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

h2 {
  font-size: 1.4em;
  margin: 0;
  margin-top: 15px;
  background: -webkit-linear-gradient(45deg, #6100FF, #FF008A, #FFC83A);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

input {
  background-color: #1F1F1F;
  color: #fff;
  font-size: 1.2em;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
}

label,
h6 {
  color: white;
  font-size: 0.8em;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 0;
  background: -webkit-linear-gradient(45deg, #6100FF, #FF008A, #FFC83A);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h6 {
  margin-top: 0;
}

p {
  color: white;
  font-size: 1em;
  font-weight: 500;
  margin: 0;
}

hr {
  color: #1F1F1F;
  min-width: 100%;
}

button {
  background-color: #5d5d5d;
  border: none;
  border-radius: 5px;
  font-size: 1.1em;
  margin-top: 15px;
  padding: 5px 20px;
  transition: all 0.1s;
}

button:hover {
  background-color: #838383;
}

#submitBtn {
  background: -webkit-linear-gradient(45deg, #6100FF, #FF008A, #FFC83A);
  transition: all 0.1s;
}

#submitBtn:hover {
  color: white;
}

.margin {
  margin-top: 15px;
}

.no-margin {
  margin: 0;
}